
import React, { useState, useContext } from 'react';
import Sidebar from '../Sidebar';
import UserContext from '../context/UserContext';
import '../Newpage.css';
import templatePdf from './template.pdf'; 

const FormFiller = () => {
  const [patientId, setPatientId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [reportGenerated, setReportGenerated] = useState(false);
  const { firstName } = useContext(UserContext);

  const useSidebarState = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const handleSidebarToggle = (isOpen) => {
      setIsSidebarOpen(isOpen);
    };
    return { isSidebarOpen, handleSidebarToggle };
  };

  const { isSidebarOpen, handleSidebarToggle } = useSidebarState();


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    if (!patientId) {
      setError('Please enter a patient ID');
      return;
    }

    setLoading(true);
    setError('');

    try {

      const pdfResponse = await fetch(templatePdf);
      const pdfBlob = await pdfResponse.blob();
      
      // Create a File object from the blob
      const pdfFile = new File([pdfBlob], 'template.pdf', { type: 'application/pdf' });
      
      // Create and send FormData
      const formData = new FormData();
      formData.append('file', pdfFile);
      const response = await fetch(`https://ai-api-staging.atria.org/Atria/PDF_Filler?patient_id=${patientId}`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to process PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setDownloadUrl(url);
      setReportGenerated(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNewId = () => {
    setPatientId('');
    setSelectedFile(null);
    setReportGenerated(false);
    setDownloadUrl('');
    setError('');
  };
  //

  return (
    <div className="newpage-background">
      <Sidebar />
      <div className={`newpage-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <div className="newpage-box">
          <h2>FORM FILLER</h2>
          <h5>Hi {firstName}, Welcome to Atria FORM FILLER</h5>

          {error && <div className="error">{error}</div>}

          {!reportGenerated ? (
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', }}>
              <input
                className="inbtnsel"
                value={patientId}
                onChange={(e) => setPatientId(e.target.value)}
                placeholder="Enter your patient ID"
                
                style={{  maxWidth: '200px' }}
              />
              
              {/* <div style={{ position: 'relative', flex: '1', maxWidth: '200px' }}>
                <div className="file-input-wrapper" style={{ position: 'relative' }}>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      opacity: 0,
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer'
                    }}
                  />
                  <div 
                    className="inbtnsel-1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      pointerEvents: 'none'
                    }}
                  >
                    {selectedFile ?'File uploaded' : 'Choose PDF'}
                   
                  </div>
                
                </div>
             
              </div> */}
            

              <button
                className="getReport-1 inbtnsel"
                type="submit"
                disabled={loading}
                style={{
                  flex: '1',
                  maxWidth: '200px',
                  backgroundColor: '#405a51',
                  color: '#ded9ce'
                }}
              >
                {loading ? 'Processing...' : 'Get Report'}
              </button>
            </form>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
              <button
                onClick={() => window.open(downloadUrl)}
                className="getReport inbtnsel"
                style={{
                  width: '200px',
                  backgroundColor: '#405a51',
                  color: '#ded9ce'
                }}
              >
                Download PDF
              </button>
              
              <button
                className="start-new-id"
                onClick={handleNewId}
                style={{
                  width: '200px',
                  marginLeft: '0',
                  backgroundColor: '#405a51',
                  color: '#ded9ce'
                }}
              >
                Start with New ID
              </button>
            </div>
          )}
        </div>
      </div>
    
    </div>
  );
};

export default FormFiller;